import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import emotion from "../../Assets/Projects/portfolio-04.jpg";
import editor from "../../Assets/Projects/portfolio-02.jpg";
import chatify from "../../Assets/Projects/portfolio-03.jpg";
import bitsOfCode from "../../Assets/Projects/portfolio-06.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={chatify}
              isBlog={false}
              title="BC Real Estate Project"
              description="React Full Stack Responsive Real Estate Website. I use React hooks, Modern CSS, Framer motion, React Router, Mongodb, cloudinary, auth0, Mantine Ui, NodeJs, ExpressJs and Swiper js library for the slider to make this site more functional."
              // ghLink="https://github.com/soumyajit4419/Chatify"
              demoLink="http://bc-real-estate-web.vercel.app/"
            />
          </Col>



          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="Bc Real Estate"
              description="This is React Fontend project. I use Reack hooks, Modern Css, Framer motion and Swiperjs library for the slider to make this site more functional."
              // ghLink="https://github.com/soumyajit4419/Bits-0f-C0de"
              demoLink="https://real-estate-project-bestchoice.pages.dev/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={editor}
              isBlog={false}
              title="School Website"
              description="This is School website. MERN Stack School Website Using ReactJs, NodeJs, MongoDB, Stripe, JWT, Tailwindcss, Express js, MongoDB and with modern UI and UX."
              // ghLink="https://github.com/soumyajit4419/Editor.io"
              demoLink="https://babjo.pages.dev/"              
            />
          </Col>

          {/* <Col md={4} className="project-card">
            <ProjectCard
              imgPath={leaf}
              isBlog={false}
              title="Plant AI"
              description="Used the plant disease dataset from Kaggle and trained a image classifer model using 'PyTorch' framework using CNN and Transfer Learning with 38 classes of various plant leaves. The model was successfully able to detect diseased and healthy leaves of 14 unique plants. I was able to achieve an accuracy of 98% by using Resnet34 pretrained model."
              ghLink="https://github.com/soumyajit4419/Plant_AI"
              demoLink="https://plant49-ai.herokuapp.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={suicide}
              isBlog={false}
              title="Ai For Social Good"
              description="Using 'Natural Launguage Processing' for the detection of suicide-related posts and user's suicide ideation in cyberspace  and thus helping in sucide prevention."
              ghLink="https://github.com/soumyajit4419/AI_For_Social_Good"
              // demoLink="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley" <--------Please include a demo link here
            />
          </Col>
 */}
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="See more..."
              description="Your can check my Github to check more of my project."
              ghLink="https://github.com/joygist"
              // demoLink="https://blogs.soumya-jit.tech/"      <--------Please include a demo link here 
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
