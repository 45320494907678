import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            <p>Hi Everyone, I am <span className="purple">Babatunde Francis Atitebi. </span>
            A <span className="purple"> Graduate and BTech holder  </span>from the prestigious<span className="purple"> State University of Technology Ogbomoso. </span>
            I have two years of experience with web Applications.</p>
            

           <p> I have experience coding React, HTML, CSS, etc. Also I am vast in Node and ExpressJs. In the past I have built CRM and ERP solutions for companies to improve their business processes.</p>

            
          </p>

          <h1 className="project-heading">
          What  <strong className="purple">I do? </strong>
        </h1>

        <p style={{ textAlign: "justify"}}>
<p>I specialize in creating exceptional user experiences and delivering high-quality software solutions as a Frontend Heavy Full Stack Engineer, blending a background in Graphics Design and UI/UX. Proficient in React, Node.js, Express.js, and WordPress, I leverage these technologies to bring designs to life, ensuring visually stunning and interactive interfaces for both web and mobile applications. </p>

<p>Transitioning from Graphics Design and UI/UX, I bring a unique perspective to web and mobile development. By combining design sensibilities with technical skills, I create visually appealing, user-friendly applications that seamlessly blend aesthetics and functionality.</p>

<h1 className="purple">My skill set includes:</h1> 
<p>
<strong className="purple">Frontend Development: </strong> Proficient in React, I excel in transforming designs into captivating and responsive user interfaces for web and mobile applications. </p>
<p>
<strong className="purple">Backend Development:</strong>Experienced in building robust and scalable server-side applications using Node.js, Express.js, and WordPress, I ensure efficient communication between the frontend and backend, facilitating a smooth data flow. </p>
<p>
<strong className="purple">WordPress Development: </strong>Adept at creating and customizing WordPress themes and plugins, I harness the power of this popular content management system to build dynamic and content-rich websites. </p>

<p><strong className="purple">Testing: </strong>Skilled in End-to-End Testing and Unit Testing, I prioritize code quality and maintainability, implementing comprehensive test suites to ensure software reliability and minimize bugs. <strong className="purple">Continuous Integration and Continuous Deployment (CI/CD): </strong> Proficient in setting up CI/CD pipelines, I automate build, test, and deployment processes for efficient and seamless software delivery.</p>

<p>Throughout my career, I have successfully delivered complex projects by collaborating closely with cross-functional teams to meet project requirements. I excel at translating design concepts into functional code and implementing innovative solutions to overcome challenges.</p>

<p>As a lifelong learner, I am dedicated to expanding my knowledge and staying updated with the latest technologies and industry best practices. I thrive in fast-paced environments that encourage creativity, collaboration, and continuous improvement.</p>

<p>If you are seeking a dedicated and results-driven Full Stack Engineer with a frontend focus and proficiency in React, Node.js, Express.js, WordPress, End-to-End Testing, Unit Testing, and CI/CD, please use the "Contact Me" section to send an email. I look forward to connecting with you.</p>
        </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
