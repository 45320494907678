import React, { useState, useRef } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Particle from "../Particle";
import imgContact from "../../Assets/Contact.png";
import emailjs from "emailjs-com";

function Contact() {
  const [done, setDone] = useState(false);
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_ii81ez8",
        "template_dkeg1vr",
        form.current,
        "cxZj_NpBjxM4qehj0"
      )
      .then(
        (result) => {
          console.log(result.text);
          setDone(true);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Connect with <strong className="purple">me</strong>
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={5} style={{ paddingBottom: 20, zIndex: 1 }}>
            <img
              src={imgContact}
              className="img-fluid moving-img"
              style={{ maxHeight: "450px" }}
              alt="Contact"
            />
          </Col>

          <Col md={5} className="project-card text-center">
            <div style={{ paddingBottom: "10px" }}>
              <Form ref={form} onSubmit={sendEmail} className="w-100">
                <div className="mb-4">
                  <input
                    type="text"
                    placeholder="Name"
                    className="form-control rounded-3"
                    name="name"
                    style={{ position: "relative" }}
                  />
                </div>
                <div className="mb-4">
                  <input
                    type="email"
                    placeholder="Email"
                    className="form-control rounded-3"
                    name="email"
                    style={{ position: "relative" }}
                  />
                </div>

                <div className="mb-4">
                  <textarea
                    rows={3}
                    placeholder="Drop a message!"
                    className="form-control rounded-3"
                    name="message"
                    style={{ position: "relative" }}
                  ></textarea>
                </div>
                <Button
                  type="submit"
                  variant="primary"
                  className="mb-4"
                  style={{ maxWidth: "250px", position: "relative" }}
                >
                  <span>Send! {done && "Thanks for Contacting me"}</span>
                </Button>
              </Form>
            </div>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Contact;
