import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { Link } from "react-router-dom";



function Home2() {
  return (
    <Container fluid className="home-section" id="home">
      <Container>
        
          <Col className="home-about-description">
            <h1 style={{ fontSize: "1.6em" }}>
            Are you in search of a seasoned <span className="purple"> Web Developer or Mobile App Developer </span> who brings a wealth of experience to the table? 🚀 Look no further – you've arrived at the perfect destination. Greetings!<br />
            Embrace quality and innovation with our services! Whether you're envisioning a stunning website or a cutting-edge mobile application, we've got you covered.  <br />
            Welcome to a journey of unparalleled development expertise!
            </h1>
          </Col>


          
        
        </Container>
    </Container>
  );
}
export default Home2;
